<template>
  <div class="dashboard-container Client">
    <SearchQuery :queryParams.sync="queryParams"/>
    <div class="dashboard-container-main Client-container">
      <TopN :queryParams.sync="queryParams" />
      <SyntheticTable
        :columes="columes"
        requestUrl="/customerBusinessData/overallQuery"
      />
    </div>
  </div>
</template>

<script>
  import SearchQuery from '../SearchQuery.vue';
  import TopN from './TopN.vue';
  import SyntheticTable from '../SyntheticTable.vue';
  export default {
    name: 'Client',
    components: {
      SearchQuery,
      TopN,
      SyntheticTable,
    },
    data() {
      return {
        queryParams: {
          timeTag: '',
          startDate: '',
          endDate: '',
        },
        columes: [
          { prop: 'customerName', label: '客户名称' },
          { prop: 'RMB', label: '人民币（万元）' },
          { prop: 'USD', label: '美元（万元）' },
          { prop: 'YEN', label: '日元（万元）' },
        ]
      }
    },
  }
</script>

<style lang="scss" scoped>
.Client{
}
</style>